const selects = document.querySelectorAll("select");
const changeEvent = new Event("change");

export default function () {
  for (const select of selects) {
    const wrap = document.createElement("DIV");
    const optionsElement = document.createElement("DIV");
    const placeholderElement = document.createElement("BUTTON");
    const update = updateOptions.bind(null, {
      optionsElement,
      placeholderElement,
      select,
      options: select.options,
      wrap,
    });

    placeholderElement.setAttribute("type", "button");
    placeholderElement.innerHTML =
      '<span class="text"></span><svg><use href="#chevron-down"/></svg>';

    select.addEventListener("change", update);

    placeholderElement.addEventListener("click", () => {
      wrap.classList.toggle("active");
    });

    wrap.addEventListener("mouseleave", () => {
      wrap.classList.remove("active");
    });

    wrap.classList.add("select-custom");
    placeholderElement.classList.add("select-custom__placeholder");
    optionsElement.classList.add("select-custom__options");

    wrap.append(placeholderElement);
    wrap.append(optionsElement);

    update();

    select.after(wrap);
  }
}

function updateOptions({
  optionsElement,
  placeholderElement,
  options,
  select,
  wrap,
}) {
  optionsElement.innerHTML = "";

  for (const option of options) {
    const optionElement = document.createElement("BUTTON");
    const isSelectedOption = option.value === select.value;

    optionElement.innerHTML =
      '<span class="text"></span><svg><use href="#union-right"/></svg>';

    optionElement.classList.add("select-custom__option");
    optionElement.classList.toggle("selected", isSelectedOption);
    optionElement.querySelector(".text").textContent = option.textContent;
    optionElement.setAttribute("data-value", option.value);
    optionElement.setAttribute("type", "button");

    optionElement.addEventListener("click", () => {
      select.value = option.value;
      select.dispatchEvent(changeEvent);

      updateOptions(...arguments);
    });

    isSelectedOption &&
      (placeholderElement.querySelector(
        ".text"
      ).textContent = `${option.textContent}`);

    optionsElement.append(optionElement);
  }

  wrap.classList.remove("active");
}
