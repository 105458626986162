import ApplicationController from "./application_controller";
import Swiper, { Navigation, Pagination } from "swiper";


export default class SwiperController extends ApplicationController {
  static targets = ['next', 'prev', 'pagination', 'slide'];
  static values = {gallery: Array};

  swiperSettings(){
    return {
      slidesPerView: "auto",
      spaceBetween: 8,
      modules: [Navigation, Pagination],
      navigation: {
        nextEl: this.nextTarget,
        prevEl: this.prevTarget,
      },
      pagination: {
        el: this.paginationTarget,
        type: "fraction",
      },
    };
  }

  connect(){
    super.connect();

    const swiper = new Swiper(this.element, this.swiperSettings());

    setTimeout(() => {
      swiper.init();
      swiper.updateSize();
    }, 100);
  }

  open(e){
    const target = e.currentTarget;
    const slide = JSON.parse(target.dataset.slide);
    const slides = this.slideTargets.map((s) => JSON.parse(s.dataset.slide));

    this.dispatch('openGallery', {slides, slide: slide.index});
  }
}
