import Swiper, { Navigation, Pagination } from "swiper";

const sliders = document.querySelectorAll(".inset-3");

export default function () {
  for (const wrap of sliders) {
    const container = wrap.querySelector(".inset-3__slider");
    const arrowPrev = wrap.querySelector(".inset-3__prev");
    const arrowNext = wrap.querySelector(".inset-3__next");
    const pagination = wrap.querySelector(".inset-3__pagination");

    new Swiper(container, {
      slidesPerView: 1,
      modules: [Navigation, Pagination],
      navigation: {
        prevEl: arrowPrev,
        nextEl: arrowNext,
      },
      pagination: {
        el: pagination,
        type: "fraction",
      },
    });
  }
}
