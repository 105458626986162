import ApplicationController from './application_controller';

import ajax from '@scripts/utils/ajax';

export default class PaginatorController extends ApplicationController {
  static values = {page: Number, pages: Number, url: String};
  static targets = ['content', 'button'];

  connect(){
    super.connect();
    this.page = 1;
  }

  next(){
    if(this.page >= this.pagesValue) {
      return;
    }

    this.page++
    this.loadPage(this.page);

    if(this.page >= this.pagesValue) {
      this.buttonTarget.style = 'display:none';
    }
  }

  loadPage(page){
    ajax.get(this.urlValue + '?page=' + page)
      .then(response => response.json())
      .then((data)=>{
        this.contentTarget.innerHTML += data.html;
      });
  }
}
