import animateScrollTo from "animated-scroll-to";
const headerContainer = document.querySelector(".header");

function scrollToElement(element, speed, scrollContainer) {
  if (typeof element == "string") {
    element = document.querySelector(element);
  }

  if (typeof scrollContainer == "string") {
    scrollContainer = document.querySelector(scrollContainer);
  }

  const elementToScroll = scrollContainer || window;
  const scrollContainerTopOffset = scrollContainer
    ? offset(elementToScroll).top - elementToScroll.scrollTop
    : 0;
  const yPosition = typeof element == "number" ? element : offset(element).top;
  const yTarget = yPosition - scrollContainerTopOffset;

  try {
    pluginScroll(yTarget, speed || 300, elementToScroll);
  } catch {
    defaultScroll(yTarget, elementToScroll);
  }
}

function scrollToPosition(yTarget, speed) {
  try {
    pluginScroll(yTarget, speed || 300, window);
  } catch {
    defaultScroll(yTarget, window);
  }
}

function pluginScroll(yTarget, speed, elementToScroll) {
  animateScrollTo(yTarget, {
    speed,
    elementToScroll,
  });
}

function defaultScroll(yTarget, elementToScroll) {
  const supportsNativeSmoothScroll =
    "scrollBehavior" in document.documentElement.style;

  if (supportsNativeSmoothScroll) {
    elementToScroll.scrollTo({
      top: yTarget,
      behavior: "smooth",
    });
  } else {
    elementToScroll.scrollTo(0, yTarget);
  }
}

export function offset(el) {
  const rect = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const headerOffset =
    window.innerWidth < 1024 ? headerContainer.clientHeight * 2 + 12 : 36;
  const top = rect.top + scrollTop - headerOffset;
  const left = rect.left + scrollLeft;

  return { top, left };
}

export { scrollToElement, scrollToPosition };
