function disableBodyScroll() {
  if(!document.body.classList.contains('body--fixed')) {
    document.body.style.top = `-${window.scrollY || window.pageYOffset || 0}px`;
    document.body.style.position = 'fixed';
    document.body.classList.add('body--fixed');
  }
}

function enableBodyScroll() {
  const scrollY = document.body.style.top;

  document.body.style.position = '';
  document.body.style.top = '';
  window.scrollTo(0, parseInt(scrollY || '0') * -1);
  document.body.classList.remove('body--fixed');
}

export {disableBodyScroll, enableBodyScroll};
