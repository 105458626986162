import { disableBodyScroll, enableBodyScroll } from "../common/scroll";
import ApplicationController from "./application_controller";

import {typoElement} from "../utils/typo";

export default class ModalController extends ApplicationController {
  static targets = ['content'];

  static activeModal = null;

  connect(){
    super.connect();
    this.overlay = document.querySelector('.modal__overlay');
  }

  open(content){
    const activeModal = ModalController.activeModal;
    if(activeModal && activeModal.element){
      activeModal.close();
    }
    ModalController.activeModal = this;

    if(content !== undefined){
      if(content instanceof Promise){
        content.then((html) => {
          this.changeContent(html)
        });
      }else{
        this.changeContent(content);
      }
    }

    this.element.classList.toggle('modal--show', true);
    this.overlay.classList.toggle('modal__overlay--show', true);

    disableBodyScroll();
  }

  toggleLoader(state){
    this.element.classList.toggle('modal--loading', state);
  }

  close(){
    this.element.classList.toggle('modal--show', false);
    this.overlay.classList.toggle('modal__overlay--show', false);

    this.activeModal = null;

    enableBodyScroll();
  }

  changeContent(html){
    this.contentTarget.innerHTML = html;
    typoElement(this.contentTarget);
  }
}
