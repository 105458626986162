import ApplicationController from "./application_controller";

export default class PicturesController extends ApplicationController {
  static targets = ['scrollContainer', 'imgWrap', 'img'];

  connect(){
    super.connect();
  }

  imgWrapTargetConnected() {
    const scrollElement = this.scrollContainerTarget;
    const imgWrapElement = this.imgWrapTarget;

    if (scrollElement && imgWrapElement) {
      if (window.innerWidth < 1280) {
        scrollElement.addEventListener("scroll", (e) => {
          imgWrapElement.classList.toggle("small", scrollElement.scrollTop > 50);
        });
      }
    }
  }

  imgTargetConnected(element) {
    element.onload = () => element.classList.add('loaded');
  }
}
