import Swiper, { Navigation } from "swiper";

const eventSliders = document.querySelectorAll(".events-slider");
const BREAKPOINT = 1024;

export default function () {
  for (const eventSliderWrap of eventSliders) {
    const container = eventSliderWrap.querySelector(".events-slider__slider");
    const arrowPrev = eventSliderWrap.querySelector(".events-slider__nav-prev");
    const arrowNext = eventSliderWrap.querySelector(".events-slider__nav-next");

    const swiper = new Swiper(container, {
      slidesPerView: 2,
      modules: [Navigation],
      enabled: false,
      navigation: {
        nextEl: arrowNext,
        prevEl: arrowPrev,
      },
    });

    window.addEventListener("resize", () => updateEnabledState(swiper));
    updateEnabledState(swiper);
  }
}

function updateEnabledState(swiper) {
  if (window.innerWidth >= BREAKPOINT) {
    swiper.enable();
  } else {
    swiper.disable();
  }
}
