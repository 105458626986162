const tabTriggers = document.querySelectorAll("[data-tab-trigger]");

export default function () {
  for (const tabTrigger of tabTriggers) {
    const splitted = tabTrigger.getAttribute("data-tab-trigger").split("#");
    const groupElements = `${splitted[0]}`;
    const targetElements = `${splitted[0]}#${splitted[1]}`;

    tabTrigger.addEventListener("click", (e) => {
      e.preventDefault();

      const isActive = tabTrigger.classList.contains("active");

      if (!isActive) {
        for (const item of [
          ...document.querySelectorAll(`[data-tab-trigger^=${groupElements}]`),
          ...document.querySelectorAll(`[data-tab-pane^=${groupElements}]`),
        ]) {
          item.classList.remove("active");
        }

        for (const item of [
          ...document.querySelectorAll(
            `[data-tab-trigger="${targetElements}"]`
          ),
          ...document.querySelectorAll(`[data-tab-pane="${targetElements}"]`),
        ]) {
          item.classList.add("active");
        }
      } else if (isActive) {
        for (const item of [
          ...document.querySelectorAll(`[data-tab-trigger^=${groupElements}]`),
          ...document.querySelectorAll(`[data-tab-pane^=${groupElements}]`),
        ]) {
          item.classList.remove("active");
        }
      }
    });
  }
}
