import ApplicationController from './application_controller';

import ajax from '@scripts/utils/ajax';

export default class NewsController extends ApplicationController {
  static values = { currentPage: Number, totalPages: Number, currentCategory: String };
  static targets = ['content', 'nextPageButton'];

  connect() {
    super.connect();

    this.actualizeNextPageButton(this.currentPageValue, this.totalPagesValue)
  }

  loadNextPage() {
    this.loadAndAppend(this.currentPageValue + 1, this.currentCategoryValue);
  }

  changeCategory(e) {
    this.loadAndReplace(1, e.target.value);
  }

  actualizeState(currentPage, totalPages, currentCategory) {
    this.currentPageValue = currentPage;
    this.totalPagesValue = totalPages;
    this.currentCategoryValue = currentCategory;
    this.actualizeNextPageButton(currentPage, totalPages);
  }

  actualizeNextPageButton(currentPage, totalPages) {
    if (currentPage >= totalPages) {
      this.nextPageButtonTarget.style = 'display:none';
    } else {
      this.nextPageButtonTarget.style = 'display:block';
    }
  }

  loadAndAppend(page, category) {
    this._load(page, category, (payload) => { this.contentTarget.innerHTML += payload.html; });
  }

  loadAndReplace(page, category) {
    this._load(page, category, (payload) => { this.contentTarget.innerHTML = payload.html; });
  }

  _load(page, category, onSuccess) {
    ajax.get(`?page=${page}&category=${category}`)
      .then(response => response.json())
      .then(payload => {
        this.actualizeState(
          payload.current_page,
          payload.total_pages,
          payload.current_category
        )

        return payload;
      })
      .then(onSuccess);
  }
}
