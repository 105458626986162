import ApplicationController from "./application_controller";

export default class PicturesController extends ApplicationController {
  static values = {active: String};

  connect(){
    super.connect();
    if(this.activeValue){
      setTimeout(() => {
        const promise = this.loadPicture(this.activeValue);
        this.dispatch('openPicture', {promise});
      }, 0);
    }
  }

  async open(e){
    e.preventDefault();

    const promise = await this.loadPicture(e.params.url);
    this.dispatch('openPicture', {promise});
  }

  loadPicture(url){
    const promise = fetch(url, {
      headers: {"Accept": "application/json"},
    }).then((response) => {
      return response.json();
    }).then((data) => {
      return data.picture;
    }).catch((reason)=>{
      alert('Ошибка при загрузке, попробуйте еще раз');
      console.error('Broken promise', reason);
    });

    window.history.pushState({type: 'picture'}, '', url);

    return promise;
  }

}
