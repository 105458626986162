import ApplicationController from "./application_controller";
import Swiper, { Navigation, Pagination } from "swiper";

export default class GalleryController extends ApplicationController {
  static targets = ['download', 'swiper', 'slide', 'next', 'prev', 'pagination'];

  get modal(){
    return this.element.modalController;
  }

  connect(){
    super.connect();
  }

  open(slides, slide){
    const html = this.render(slides);
    this.slide = slide;
    this.slides = slides

    this.modal.open(html);
  }

  render(slides){
    const slidesHTML = slides.map((slide) => {
      return `
      <div class="modal-gallery__slide swiper-slide" data-gallery-target="slide">
        <img alt="" src="${slide.url}" />
      </div>
      `;
    })

    return `
    <div class="modal-gallery__slider swiper" data-gallery-target="swiper">
      <div class="swiper-wrapper">
        ${slidesHTML}
      </div>
    </div>`;
  }

  swiperTargetConnected(element){
    this.swiper = new Swiper(element, {
      slidesPerView: 1,
      spaceBetween: 8,
      modules: [Navigation, Pagination],
      navigation: {
        prevEl: this.prevTarget,
        nextEl: this.nextTarget,
      },
      pagination: {
        el: this.paginationTarget,
        type: "fraction",
      },
    });

    this.swiper.on('slideChange', () => {
      const slide = this.slides[this.swiper.activeIndex];
      this.downloadTarget.setAttribute('href', slide.download);
    });

    this.swiper.slideTo(this.slide, 0, true)

  }

}
