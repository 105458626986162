import Typograf from "typograf";

const tp = new Typograf({
  locale: ["ru", "en-US"],
  htmlEntity: { type: "name" },
});

tp.disableRule("*");
tp.enableRule("common/nbsp/afterNumber");
tp.enableRule("common/nbsp/afterParagraphMark");
tp.enableRule("common/nbsp/afterSectionMark");
tp.enableRule("common/nbsp/afterShortWord");
tp.enableRule("common/nbsp/beforeShortLastNumber");
tp.enableRule("common/nbsp/beforeShortLastWord");
tp.enableRule("common/nbsp/replaceNbsp");

export default function () {
  window.addEventListener("DOMContentLoaded", () => {
    for (const element of document.querySelectorAll(
      ".typo, p, h1, h2, h3, h4, h5, h6"
    )) {
      typoElement(element);
    }
  });
}

export function typoElement(element) {
  if (element) {
    element.innerHTML = tp.execute(element.innerHTML);
  }
}
