import ApplicationController from "./application_controller";
import { scrollToPosition, scrollToElement } from "../common/scrollto";

export default class MuseumController extends ApplicationController {
  static targets = ['galleryModal', 'pictureModal', 'header'];

  get galleryModal(){
    return this.galleryModalTarget.galleryController;
  }

  get pictureModal(){
    return this.pictureModalTarget.modalController;
  }

  connect(){
    super.connect();
    this.listen('openGallery', ({detail}) => this.openGallery(detail.slides, detail.slide));
    this.listen('openPicture', ({detail}) => this.openPicture(detail.promise))

    window.addEventListener('hashchange', (e) => {
      e.preventDefault();
      const hash = window.location.hash;
      if(hash && hash.length > 0){
        const target = document.querySelector(hash);
        if(target){
          scrollToElement(target);
        }
      }
    });

    const anchorLinks = document.querySelectorAll('a[href*="#"]');
    for (const link of anchorLinks) {
      link.addEventListener('click', (e) => {
        if(window.location.pathname == link.pathname){
          e.preventDefault();
        } else {
          return;
        }

        const match = link.href.match(/#(.+)/);
        const hash = match && match[0];

        if(hash && hash.length > 0){
          this.headerTarget.headerController.toggleMenuState(false);
          window.history.pushState('nav', null, e.target.href);
          window.dispatchEvent(new HashChangeEvent('hashchange'));
        }
      });
    }
  }

  openGallery(slides, slide){
    this.galleryModal.open(slides, slide);
  }

  openPicture(promise){
    this.pictureModal.open(promise)
  }

  toTop(e){
    e.preventDefault();
    scrollToPosition(0);
  }

}
