import { disableBodyScroll, enableBodyScroll } from "../common/scroll";
import { scrollToElement } from "../common/scrollto";
const body = document.querySelector("body");
const anchorLinks = document.querySelectorAll('a[href^="#"]');
const autoIdSections = document.querySelectorAll('[data-autoid]');

const mobileContainer = document.querySelector(".article-mobile-nav");
const mobileContainerPlaceholder =
  mobileContainer &&
  mobileContainer.querySelector(".article-mobile-nav__placeholder");
const mobileContainerPlaceholderText =
  mobileContainer &&
  mobileContainer.querySelector(".article-mobile-nav__placeholder .text");

const mobileNavTarget = document.querySelector("[data-hide-mobile-nav]");

const observerOptions = {
  rootMargin: "0% 0px -50% 0px",
  threshold: [0],
};

const mobileNavVisibilityObserverOptions = {
  rootMargin: "20% 0px 100% 0px",
  threshold: [0],
};

export default function () {
  if (anchorLinks) {
    const sections = [];

    autoIdSections && autoIdSections.forEach((section, i) => {
      section.id = `section-auto-${i + 1}`
    })

    for (const link of anchorLinks) {
      const match = link.href.match(/#(.+)/);
      const id = match && match[0];
      const target = id && document.querySelector(id);

      if (target) {
        sections.push(target);

        link.addEventListener("click", (e) => {
          e.preventDefault();
          link.classList.contains("article-mobile-nav__link") &&
            closeMobileNav();
          scrollToElement(target);
        });
      }
    }

    if (sections && sections.length) {
      addObservers(sections);

      mobileContainerPlaceholder.addEventListener("click", () => {
        mobileContainer.classList.contains("open")
          ? closeMobileNav()
          : openMobileNav();
      });
    }

    if (mobileNavTarget) {
      const showMobileNavObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          body &&
            body.classList.toggle(
              "body--article-nav-mobile-visible",
              !entry.isIntersecting
            );
        });
      }, mobileNavVisibilityObserverOptions);

      showMobileNavObserver.observe(mobileNavTarget);
    } else {
      body && body.classList.add("body--article-nav-mobile-visible");
    }
  }
}

function addObservers(sections) {
  for (const section of sections) {
    const id = section.getAttribute("id");
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        entry.isIntersecting && setActiveNavItems(id);
      });
    }, observerOptions);

    observer.observe(section);
  }

  sections[0] && setActiveNavItems(sections[0].getAttribute("id"));
}

function setActiveNavItems(id) {
  const notActiveTemplate = `a:not([href$="#${id}"]):not([href="#"])`;
  const activeTemplate = `a[href$="#${id}"]:not([href="#"])`;

  const notActiveLinks = document.querySelectorAll(notActiveTemplate);
  const activeLinks = document.querySelectorAll(activeTemplate);

  for (const link of notActiveLinks) {
    link.classList.remove("active");
  }

  for (const link of activeLinks) {
    link.classList.add("active");

    if (link.classList.contains("article-mobile-nav__link")) {
      mobileContainerPlaceholderText.textContent = link.textContent;
    }
  }
}

function openMobileNav() {
  mobileContainer.classList.add("open");
  disableBodyScroll();
}

function closeMobileNav() {
  mobileContainer.classList.remove("open");
  enableBodyScroll();
}
