function post(url, data){
  const token = document.querySelector('meta[name=csrf-token]').content;

  const isFormData = data instanceof FormData;

  const headers = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-Token': token,
    'Accept': 'application/json',
  }

  const body = isFormData ? data : JSON.stringify(data);

  if(!isFormData){
    headers['Content-Type'] = 'application/json';
  }

  return fetch(url, {
    method: 'POST',
    headers,
    body,
  });
}

function get(url){
  const headers = {
    'X-Requested-With': 'XMLHttpRequest',
    'Accept': 'application/json',
  }

  return fetch(url, {headers});
}

export default {post, get};
