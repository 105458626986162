import cookie from "cookie";

const cookiePaneList = document.querySelectorAll("[data-cookie]");

export default function () {
  for (const cookiePane of cookiePaneList) {
    const cookieBtn =
      cookiePane && cookiePane.querySelector("[data-cookie-btn]");
    const pageCookie = document.cookie || "";
    const cookieName = cookiePane.getAttribute("data-cookie");
    const clicked = cookie.parse(pageCookie)[cookieName] === "true";

    if (!clicked) {
      cookiePane.classList.remove("hidden");

      cookieBtn.addEventListener("click", (e) => {
        e.preventDefault();

        document.cookie = cookie.serialize(cookieName, "true", {
          maxAge: 60 * 60 * 24 * 365,
          path: "/",
        });

        cookiePane.classList.add("hidden");
      });
    }
  }
}
