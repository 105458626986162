import "babel-polyfill";

import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

import tab from "./common/tab";
import select from "./common/select";
import eventsSlider from "./blocks/events-slider";
import introSlider from "./blocks/intro-slider";
import inset3 from "./blocks/inset-3";
import articleNav from "./blocks/article-nav";
import bottomPane from "./blocks/bottom-pane";
import scrollEvents from "./blocks/scroll-events";
import typo from "./utils/typo";

typo();
tab();
select();
eventsSlider();
introSlider();
inset3();
articleNav();
bottomPane();
scrollEvents();

const application = Application.start();
const context = require.context("./controllers", true, /\.js$/);
application.load(definitionsFromContext(context));
