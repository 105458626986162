import Swiper, { Pagination, Autoplay } from "swiper";

const introSliders = document.querySelectorAll(".intro__slider");
const DELAY = 5000;
const SPEED = 400;

export default function () {
  for (const introSlider of introSliders) {
    const pagination = introSlider.querySelector(".intro__slider-pagination");
    new Swiper(introSlider, {
      modules: [Pagination, Autoplay],
      speed: SPEED,
      autoplay: {
        delay: DELAY,
      },
      pagination: {
        el: pagination,
        bulletClass: "intro__slider-bullet",
        bulletActiveClass: "active",
      },
    });

    pagination.style.animationDuration = `${DELAY + SPEED}ms`;
  }
}
