import ApplicationController from "./application_controller";

export default class FilterController extends ApplicationController {
  static values = { category: String };
  static targets = ["item"];

  connect() {
    super.connect();
    this.categoryValue = "";
  }

  select(e) {
    this.categoryValue = e.target.value;
  }

  categoryValueChanged() {
    for (const item of this.itemTargets) {
      const active =
        this.categoryValue.length == 0 ||
        this.categoryValue === item.getAttribute("data-category");

      item.classList.toggle("hidden", !active);
    }
  }
}
