import ApplicationController from "./application_controller";
import Swiper, { Navigation, Pagination } from "swiper";
import { disableBodyScroll, enableBodyScroll } from "../common/scroll";

export default class HeaderController extends ApplicationController {
  static targets = ['rootmenu', 'submenu'];

  lastScrollPosition = 0;

  connect(){
    super.connect();

    window.addEventListener("scroll", this.updateHeaderDirection, { passive: true });
    window.addEventListener("resize", this.updateHeaderDirection, { passive: true });

    this.updateHeaderDirection();
  }

  toggleMenu(e){
    e.preventDefault();

    this.toggleMenuState(!document.body.classList.contains("menu-open"));
  }

  toggleMenuState(state){
    document.body.classList.toggle("menu-open", state);

    if (state) {
      disableBodyScroll();
    } else {
      enableBodyScroll();
    }
  }

  openSubmenu(e) {
    e.preventDefault();
    this.setSubmenu(e.params.submenu);
  }

  closeSubmenu(e){
    e.preventDefault();
    this.setSubmenu();
  }

  setSubmenu(id) {
    for (const item of this.submenuTargets) {
      if(item.dataset.submenu == id){
        item.classList.add("active");
      } else {
        item.classList.remove("active");
      }
    }

    this.rootmenuTarget.classList.toggle('active', !id);
  }

  updateHeaderDirection() {
    requestAnimationFrame(() => {
      const newScrollPosition = window.scrollY;
      const isDirectionDown = this.lastScrollPosition < newScrollPosition;
      const body = document.body;

      if (!body.classList.contains("body--fixed")) {
        body.classList.toggle("body--header-small", newScrollPosition > 61);

        if (newScrollPosition) {
          body.classList.toggle("body--scroll-up", !isDirectionDown);
          body.classList.toggle("body--scroll-down", isDirectionDown);
        }

        this.lastScrollPosition = newScrollPosition;
      }
    });
  }

}
