import { Controller } from "@hotwired/stimulus"

export default class ApplicationController extends Controller {
  connect(){
    const id = camelCase(this.identifier);
    this.element[`${id}Controller`] = this;
  }

  dispatch(name, detail){
    this.element.dispatchEvent(new CustomEvent(name, {detail, bubbles: true}));
  }

  listen(name, handler){
    this.element.addEventListener(name, handler);
  }
}

function camelCase(str) {
  return str.replace(/[_.-]+(\w|$)/g, (_,x) => x.toUpperCase());
}
